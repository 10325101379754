.nav_area {
  position: sticky;
  top: 0;
  z-index: 3;
  transition: background-color 0.4s ease, box-shadow 0.5s ease;
  background-color: #fff; /* Ensure background color */
}



.nav_area_home {
  border-bottom: 1px solid rgb(0 0 0 / 8%);
}

.nav_area_common {
  background-color: #fff;
}

.nav_container {
  display: flex;
  justify-content: space-between; /* Space between left and right sections */
  align-items: center;
  padding: 13px 0; /* Vertical padding */
}

.nav_container_left {
  display: flex;
  align-items: center;
}

.nav_container_right {
  display: flex;
  align-items: center; /* Align icon and number vertically */
  gap: 10px; /* Space between the phone icon and phone number */
}

.phone_icon {
  font-size: 20px; /* Adjust size of the phone icon */
  color: #226db4; /* Icon color to match the design */
}

.phone_number {
  color: #226db4; /* Blue color for visibility */
  font-size: 16px;
  font-weight: bold;
  text-decoration: none; /* Remove underline */
}

.phone_number:hover {
  text-decoration: underline; /* Optional: Add underline on hover */
}

.navbar_btn {
  display: flex;
  gap: 20px;
}

.nav_area.onscroll_bg {
  background-color: #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}

.nav_area_blog {
  box-shadow: 0 0 1px 0px rgb(0 0 0 / 50%);
}

/* Dropdown menu */
.withsubmenunav {
  position: relative;
  cursor: pointer;
}

.nav_subulweb {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  top: 50px;
  list-style: none;
  background-color: #fff;
  min-width: 180px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px #0000001a;
  transition: 0.8s;
  padding: 20px;
}

.withsubmenunav:hover .nav_subulweb {
  opacity: 1;
  top: 35px;
  visibility: visible;
}

.nav_subul li {
  padding: 10px 0;
}

.nav_subul li a {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  white-space: nowrap;
}

.currentPage {
  color: var(--primary-Color, #226db4);
  padding-bottom: 5px;
  border-bottom: 2px solid var(--primary-Color, #226db4);
}

/* Responsive styles for smaller screens */
@media only screen and (max-width: 925px) {
  .navbar_desktop,
  .navbar_desktop .navbar_btn {
    display: none;
  }

  .bm-burger-button {
    display: block;
  }

  .nav_container {
    padding: 13px 20px; /* Adjust padding for smaller screens */
  }

  .nav_logo {
    height: 30px;
    width: auto;
  }

  .mobile_nav_btn {
    padding: 5px 10px;
  }

  .nav_container_right {
    gap: 15px; /* Smaller gap for mobile */
  }
}

@media only screen and (max-width: 367px) {
  .nav_container {
    padding: 10px 15px; /* Adjust padding for very small screens */
  }

  .nav_container_right {
    gap: 10px; /* Reduce gap further */
  }
}
